import React, { useEffect } from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AssetList from '../../_molecules/AssetList/assetList';

import { isActionLoading } from '../../../redux/selectors/loadingSelectors';
import { FETCH_VALOR_CREST } from '../../../redux/constants/actionTypes';
import { getValorCrests } from '../../../redux/actions/valorCrest';
import ConnectWallet from '../../_molecules/ConnectWallet/connectWallet';
import EmptyResult from '../../_molecules/EmptyResult/emptyResult';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

export default function InventoryConsumables() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const valorCrests = useSelector((state) => state.valorCrest.items);
  const isLoading = useSelector((state) =>
    isActionLoading(state, FETCH_VALOR_CREST),
  );

  const fetched = useSelector((state) => state.valorCrest.fetched);
  const accessToken = useSelector((state) => state.user.accessToken);
  const user = useSelector((state) => state.user.user);

  const handleClickLogin = () => {
    history.push('/auth/login');
  };

  useEffect(async () => {
    if (accessToken && !!user?.walletAddress && !fetched) {
      dispatch(getValorCrests());
    }
  }, [user, accessToken, fetched]);

  if (!accessToken) {
    return <ConnectWallet onLogin={handleClickLogin} text="Keys" />;
  }
  return (
    <div className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
          <Typography variant="h4" align="left" color="primary">
            {`Owned (${valorCrests?.length || 0})`}
          </Typography>
          {/* <Box ml={4}>
            <Link
              component="button"
              onClick={() =>
                window.open(
                  'https://opensea.io/collection/oxya-origin-alpha-keys',
                )
              }
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h5" className={classes.buyText}>
                  For Sale on Opensea
                  <ArrowRightAltIcon
                    style={{ marginLeft: 10 }}
                    className={classes.icon}
                  />
                </Typography>
              </Box>
            </Link>
          </Box> */}
        </Box>
      </Box>
      <Box mb={3} />
      {!user?.walletAddress && (
        <EmptyResult text="You don't have EVM wallet linked to your account">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() => {
              history.push('/account');
            }}
          >
            Link an Wallet
          </ButtonPerso>
        </EmptyResult>
      )}
      {fetched && valorCrests?.length === 0 ? (
        <EmptyResult text="You don t have any valor crests">
          <Box mt={4} />
          <ButtonPerso
            shrink
            onClick={() =>
              window.open(
                'https://marketplace.oxyaorigin.com/collections/0xdf3252243f522d76ac110d41213f98c2f578fa7c',
              )
            }
          >
            You can buy a valor crest in tokentrove
          </ButtonPerso>
        </EmptyResult>
      ) : (
        <AssetList
          assets={valorCrests}
          assetType="valorCrests"
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

const useStyle = makeStyles(({ spacing, palette }) => ({
  container: {
    padding: spacing(3),
    paddingTop: spacing(2.8),
    width: '100%',
    height: '100%',
  },
  title: {
    color: 'white',
  },
  buyText: {
    color: palette.text.secondary,
    textDecoration: 'underline',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecorationColor: palette.primary.light,
      '& svg': {
        color: palette.primary.light,
      },
    },
  },
}));
