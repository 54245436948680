import React, { useEffect, useMemo } from 'react';
import Proptypes from 'prop-types';
import { Box, makeStyles, Typography, Link, Button } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLands } from '../../../redux/actions/lands';

import { heightHeader } from '../../../constants/size';
import ModalCenter from '../../_atoms/ModalCenter/modal-center';
import { textModalRegister } from '../../../constants/text';
import ButtonPerso from '../../_atoms/ButtonPerso/button-perso';

import iconDiscord from '../../../assets/social/discord.svg';
import iconTwitter from '../../../assets/social/twitter.svg';
import { getAvatars } from '../../../redux/actions/avatarActions';
import { getValorCrests } from '../../../redux/actions/valorCrest';
import { CODE_EPIC } from '../../../constants/code';

export default function Dashboard() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const accessToken = useSelector((state) => state.user.accessToken);

  const fetchedLands = useSelector((state) => state.land.fetched);
  const fetchedAvatars = useSelector((state) => state.avatar.fetched);
  const fetchedValorCrests = useSelector((state) => state.valorCrest.fetched);

  useEffect(() => {
    if (accessToken && user?.walletAddress) {
      if (!fetchedLands) {
        dispatch(getLands());
      }
      if (!fetchedAvatars) {
        dispatch(getAvatars());
      }
    }
  }, [user, fetchedAvatars, fetchedLands]);

  useEffect(async () => {
    if (accessToken && !!user?.walletAddress && !fetchedValorCrests) {
      dispatch(getValorCrests());
    }
  }, [user, fetchedValorCrests]);

  const isRegistered = useMemo(
    () => history.location?.state?.modalRegister,
    [history.location.state],
  );

  const handleCloseModal = () => {
    if (history.location.state?.modalRegister) {
      history.replace();
    }
  };

  const handleAccountPage = () => {
    history.push('/account');
  };

  return (
    <>
      {isRegistered && (
        <ModalCenter
          open={isRegistered}
          onClose={handleCloseModal}
          classNameChildren={classes.modalChildrenRegister}
          classNamePaper={classes.modalPaperRegister}
          closeBtn={false}
        >
          <Typography variant="h2" className={classes.titleModalRegister}>
            {textModalRegister.title}
          </Typography>
          <Typography variant="body1" className={classes.detailsModalRegister}>
            {textModalRegister.details}
          </Typography>
          <ul>
            {textModalRegister.listDetails.map((elem) => (
              <li key={`list-details-register-${elem.key}`}>{elem.text}</li>
            ))}
          </ul>
          <Box className={classes.boxSocialRegister}>
            <Link
              style={{ display: 'inline-flex' }}
              href="https://discord.com/invite/oxyaorigin"
              target="_blank"
            >
              <img src={iconDiscord} width={20} height={19} alt="discord" />
            </Link>
            <Link
              href="https://twitter.com/OxyaOrigin"
              style={{ display: 'inline-flex' }}
              target="_blank"
            >
              <img src={iconTwitter} width={20} height={21} alt="twitter" />
            </Link>
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={10}>
            <ButtonPerso
              className={classes.btnAccountPage}
              onClick={handleAccountPage}
            >
              My Account
            </ButtonPerso>
            <Button onClick={handleCloseModal}>Skip</Button>
          </Box>
        </ModalCenter>
      )}
      <div className={classes.container}>
        <div className={classes.global}>
          <div className={classes.wrapper}>
            <div className={classes.topRightBox} />
            <div className={classes.topLeftBox} />
            <div className={classes.bottomRightBox} />
            <div className={classes.bottomLeftBox} />
            <Typography color="textPrimary" align="center" variant="h4">
              WELCOME TO
              <Typography component="span" color="primary" variant="h4">
                {' '}
                OXYA Origin
              </Typography>
            </Typography>
            <>
              <Typography
                color="textPrimary"
                align="center"
                variant="h5"
                style={{ marginTop: '10px' }}
              >
                Road To Genesis Early-Access Alpha is available on{' '}
                <Link
                  href="https://store.epicgames.com/p/oxya-origin-road-to-genesis-6ed2a9"
                  target="_blank"
                >
                  Epic Games
                </Link>{' '}
                <br />
                with the following code:{' '}
                <span style={{ fontWeight: 'bold' }}>{CODE_EPIC}</span>
              </Typography>
            </>
            <Link href="https://lnkfi.re/OxyaOrigin" target="_blank">
              <Typography
                color="primary"
                align="center"
                variant="h4"
                style={{ marginTop: '10px', textDecoration: 'underline' }}
              >
                Learn More
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

// TODO: Change Proptypes object by the shape of the object
Dashboard.propTypes = {
  location: Proptypes.shape({
    hash: Proptypes.string,
    key: Proptypes.string,
    search: Proptypes.string,
    state: Proptypes.object,
  }),
};

Dashboard.defaultProps = {
  location: {},
};

const useStyle = makeStyles(({ palette, breakpoints, spacing }) => ({
  panelTitleLeaderboard: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalChildrenRegister: {},
  modalPaperRegister: {
    background: '#091E31',
    '& ul': {
      paddingLeft: '3rem',
      '& li': {
        fontWeight: 600,
      },
    },
  },
  titleModalRegister: {
    fontFamily: 'Akira',
    fontSize: '3.6rem',
    fontWeight: 800,
    lineHeight: '41px',
    whiteSpace: 'pre-wrap',
    [breakpoints.down(400)]: {
      fontSize: '3rem',
    },
  },
  detailsModalRegister: {
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '29px',
    maxWidth: '100%',
    margin: '1rem 0',
  },
  boxSocialRegister: {
    display: 'flex',
    gap: '2rem',
    margin: '2rem 0',
  },
  btnAccountPage: {
    width: '100%',
    background:
      'linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)',
    border: 'none',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '1rem',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '4rem',
    minHeight: `calc(100vh - 4rem - ${heightHeader})`,
  },
  initialHeight: {
    height: 'initial',
  },
  bg: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    height: `calc(100vh - 4rem - ${heightHeader})`,
  },
  wrapperNoConnected: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    color: palette.common.white,
    textShadow: '2px 9px 51px #10193A70',
    textStroke: '1px RGBA(0, 0, 0, 0.6)',
    fontSize: '6rem',
    [breakpoints.down(600)]: {
      fontSize: '3rem',
    },
  },
  btnConnect: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    padding: spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  global: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '95%',
    margin: 'auto',
  },
  wrapper: {
    padding: spacing(6),
    position: 'relative',
    textTransform: 'uppercase',
    color: palette.primary.light,
  },
  topRightBox: {
    position: 'absolute',
    top: -1,
    right: -1,
    borderTop: `2px solid ${palette.primary.light}`,
    borderRight: `2px solid ${palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  topLeftBox: {
    position: 'absolute',
    top: -1,
    left: -1,
    borderTop: `2px solid ${palette.primary.light}`,
    borderLeft: `2px solid ${palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  bottomRightBox: {
    position: 'absolute',
    bottom: -1,
    right: -1,
    borderBottom: `2px solid ${palette.primary.light}`,
    borderRight: `2px solid ${palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  bottomLeftBox: {
    position: 'absolute',
    bottom: -1,
    left: -1,
    borderBottom: `2px solid ${palette.primary.light}`,
    borderLeft: `2px solid ${palette.primary.light}`,
    width: '20%',
    height: '30%',
  },
  wrapperImgLauncher: {
    display: 'flex',
    gap: '3rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    '& img': {
      width: '12rem',
    },
  },
}));
